<template>
    <div>
        <!--components/CLISessionList.vue-->
        <div class="cli-session-list-action-bar">
            <input type="checkbox"  class="select-all-checkbox" @change="select_unselect_all"/>
            <button class="btn btn-sm btn-outline-secondary" @click.stop.prevent="delete_sessions">
                <span>Delete</span>
            </button>
            <button v-if="false" class="btn btn-sm btn-outline-secondary float-end" @click.stop.prevent="add_session">Create</button>

        </div>

        <LazyCLISession 
            v-for="(session, index) in props.sessions" 
            :key="index" 
            :session="session"
            @notify-delete="$emit('notify-delete')"
        />
    </div>
</template>

<script setup>
const props = defineProps({
    sessions: {
        type: Array,
        required: true,
    }
});
const emits = defineEmits(["notify-delete"]);

const select_unselect_all = (event) => {
    let checked = event.target.checked;
    $('input.cli-session-id').forEach((session_checkbox) => {
        session_checkbox.checked = checked;
    })
}

const delete_sessions = async (event) => {
    var convs = document.querySelectorAll(".cli-session-id");
    var selected_convs = [];
    for (var i=0; i < convs.length; i++){
        if (convs[i].checked){
            var conv_id = convs[i].value;
            selected_convs.push(conv_id);
        }
    }

    if (selected_convs.length > 0) {
        dkConfirm("Are you sure you want to delete all the selected conversations?", async function() {
            let reqObj = {};
            reqObj.conv_list = selected_convs;
            await showWaitPage();
            const response = await logFetchAJAX(getUrl(`deleteConvList`), {
                method: "POST",
                body: reqObj 
            });
            emits("notify-delete");

        }, function(){})

    }
    /*
    if ((selected_convs.length > 0) && (confirm("Are you sure you want to delete all the selected conversations?"))) {
        let reqObj = {};
        reqObj.conv_list = selected_convs;
        await showWaitPage();
        const response = await logFetchAJAX(getUrl(`deleteConvList`), {
            method: "POST",
            body: reqObj 
        });
        emits("notify-delete");
    }
    */
}

const add_session_ok_clicked = async () => {
    let new_session_title = document.querySelector('#new_session_title').value.trim();
    if (new_session_title == '') {
        document.querySelector('#new_session_title_required').innerHTML = "Please provide a session title is required (it is required)";
        return false;
    } else {
        let conv_id = makeid(32);
        let data = await fetchLegacyConvMessages(conv_id, new_session_title);
        setTimeout(() => {
            navigateTo(`/cli-sessions/${conv_id}`)
        }, 10)
        return true;
    }
}
const add_session = (event) => {
    let html = `<form id="new_session_form"><p id="new_session_title_required"></p><p>Give this session a title: <input type="text" id="new_session_title"></p></form>`;
    dkPrompt(html, add_session_ok_clicked, function(){}, 'new_session_form');
}

</script>

<style scoped>
.cli-session-list-action-bar {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 14px;
}
.select-all-checkbox {
    margin-right: 10px;
}
</style>