export const fetchLegacyConvMessages = async (legacy_conv_id, title, conv_type) => {

    const url = getUrl(`/getConvOrCreate`);
    title = title || "";
    conv_type = conv_type || "aiagent";

    let body = {
        'id': legacy_conv_id
    };

    if (title != '') {
        body.subject = title;
    }

    body.conv_type = conv_type;

    if (typeof(window) != 'undefined') {
        const result = await logFetchAJAX(url, {
            method: 'POST',
            body: body
        });
        return result;    
    } else {
        return [];
    }
}
