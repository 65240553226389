<template>
    <div class="bottom_row">
        <!--
            To use this component, we must pass it the prop element_id.  This will be used as the ID of the code element.  We do not pass the 
            execution result as a prop to this component.  We expect the rest of the code to look up the code element by the given ID, and 
            then call the displayTaskExecResult composable, given the code element, and the data that we need to display.  The 
            displayTaskExecResult composable has logic to detect whether to show or hide the expand / collapse icon.  For now, we will not 
            update the code inside updateTaskExecResultsView, and TaskTitleContainer to use this component and displayTaskExecResult yet.
            Search TaskTitleContainer for class_execution_results_box.  When we update TaskTitleContainer to use this component, we can 
            remove the expand_prev function.
        -->
        <pre class="cli-session-page class_execution_results_box execution_result" ><code :id="props.element_id" class="left_side_execution_result"></code></pre>
        <div @click="expand_collapse" class="class_execution_result_expand_arrow" :id="'expand_arrow_' + props.element_id" style="text-align:center">
          <i class="fa-light fa-caret-down"></i>
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    element_id: {
        type: String,
        required: true 
    }
})

const expand_collapse = (event) => {
    if (event.target.classList.contains('fa-caret-down')) {
        let bottom_row = findAncestorWithClass(event.target, "bottom_row");
        let element = bottom_row.querySelector('.class_execution_results_box');
        let bottom_scrollbar_height = 0;
        if (element.clientWidth < element.scrollWidth) {
            bottom_scrollbar_height = 20;
        }
        element.style.height = (element.scrollHeight + 2 + bottom_scrollbar_height) + 'px';
        element.style.maxHeight = element.style.height;
        event.target.classList.remove('fa-caret-down');
        event.target.classList.add('fa-caret-up');
    } else {
        let bottom_row = findAncestorWithClass(event.target, "bottom_row");
        let element = bottom_row.querySelector('.class_execution_results_box');
        element.style.height = (200) + 'px';
        element.style.maxHeight = element.style.height;
        event.target.classList.remove('fa-caret-up');
        event.target.classList.add('fa-caret-down');
    }
}

</script>
