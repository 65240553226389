export const fetchCLISessions = async () => {
    let reqObj = {};
    reqObj['query'] = {}
    // reqObj['query']['search_phrase'] = search_phrase;

    reqObj.start = 0;
    reqObj.limit = 100;
    reqObj.conv_type = 'clisession,aiagent';
    reqObj.dag_id = '__default_dag'
    
    const response = await logFetchAJAX(getUrl(`getConvThreadIds`), {
      method: "POST",
      body: reqObj 
    });
    return response;
}
