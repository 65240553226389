<template>
    <div class="dagify">
        <!--components/Dagify.vue-->
        <p class="text-end">
            <button class="btn btn-outline-primary" @click.prevent.stop="show_invite_user_to_session_dialog">Manage Collaborators</button>
        </p>
        <teleport to="#dagknows_generic_dialog_body" v-if="manage_session_collaborator_ref.valueOf()">
            <LazyManageSessionCollaborators v-if="manage_session_collaborator_ref.valueOf()" :conv_id="props.conv_id" :conv_type="conv_type"/>
        </teleport>
        <p class="mt-3 instruction" v-show="(code_or_command_count.valueOf() > 0) || (conv_type == 'clisession')">
            Please select the checkbox next to the commands that you want to keep, and click on the "Create Task" button below.
            <input 
                class="form-check-input" 
                type="checkbox" checked="true" 
                id='session_detail_select_or_unselect_all'
                @click="session_detail_select_or_unselect_all"
            />
        </p>
        <div v-for="(message, index) in results_array_ref.valueOf()" :key="index" :message="message">
            <DagifyCommand :message="message" :index="index"/>
        </div>
        <select v-if="false" class="form-select class_proxy_selector" @change="proxy_selected" v-model="execute_proxy_alias" style="display: inline-block; width: 100px">
            <option value="">Select a proxy</option>
            <option v-for="(proxy_alias, index) in proxy_aliases_ref.valueOf()" :key="index" :value="proxy_alias">{{ proxy_alias }}</option>
        </select>
        <label v-if="false" class="auto_exec_label"> <input type="checkbox" v-model="auto_execute">auto exec</label>
        <textarea v-if="false"
            class="message_input_field" 
            rows="4" 
            placeholder="Enter your chat message or query" 
            v-model="input_message_ref" 
            @keyup.enter="send_message"
        ></textarea>
        <div class="create_task_btn_container">
            <button v-if="false" class="btn btn-primary" @click.prevent.stop="send_message">Send</button>
            <button class="btn btn-secondary" @click.prevent.stop="create_task" v-show="(code_or_command_count.valueOf() > 0) || (conv_type == 'clisession')">Create Task</button>
        </div>
    </div>
</template>

<script setup>
import { Codemirror } from "vue-codemirror";

const generate_access_token_for_current_user = async () => {
    // 1209600 is 14 days
    const result = await generateAccessToken({ 
        exp: 1209600, 
        label: 'user' 
    }).catch((err) => console.log(err))
    let generated_token = ''
    if(result && result.access_tokens) {
        generated_token = Object.keys(result.access_tokens)[0];
    }
    return generated_token;
}

const userInfoStore = useUserInfoStore();
let user_info = userInfoStore.getUserInfo;

let generated_access_token = "";
let auto_execute = true;

let input_message_ref = ref("");
let results_array_ref = ref([]);
let session_title = '';
const props = defineProps({
    conv_id: {
        type: String,
        required: true
    }
})

const manage_session_collaborator_ref = ref(false)
const show_invite_user_to_session_dialog = (event) => {
    manage_session_collaborator_ref.value = true;
    showGenericModal();
}

const proxy_selected = async (event) => {
  if (execute_proxy_alias == "") {
    proxy_roles_ref.value = [];
  } else {
    let user_id = user_info.uid;
    let all_proxy_roles_for_user = await getProxyRolesForUser(user_id);
    if (all_proxy_roles_for_user.hasOwnProperty(execute_proxy_alias)) {
      proxy_roles_ref.value = all_proxy_roles_for_user[execute_proxy_alias];
    } else {
      proxy_roles_ref.value = [];      
    }
    if (proxy_roles_ref.value.length == 0) {
      dkAlert("Error: You do not have any role on the selected proxy.");
    }
  }
}

const proxy_aliases_ref = ref([]);
const proxy_roles_ref = ref([]);
let execute_proxy_alias = "";
const settings = await getSettings();
const proxy_aliases = extractProxyAliasesFromSettings(settings);
proxy_aliases_ref.value = proxy_aliases;
if (proxy_aliases.length == 1) {
    execute_proxy_alias = proxy_aliases[0];
    proxy_selected();
}


const session_detail_select_or_unselect_all = (event) => {
    let desired_state = event.target.checked;
    $('input[type=checkbox]').forEach(function (e) {
        e.checked = desired_state;
    });
}

const create_task = async (event) => {
    await showWaitPage();
    let commands = [];
    let outputs = [];
    let timestamps = [];
    $('input[type=checkbox]').filter(session_detailed_filter_out_select_all).forEach(function (e) {
        if (e.checked) {          
        let id = e.getAttribute('id');
        let index = id.replace('dagify_', '');
        let msgtype = results_array_ref.value[index]['type'];
        let output = results_array_ref.value[index]['output'];
        let timestamp = results_array_ref.value[index]['timestamp'];

        if (msgtype == "aiagent") {
          let output = results_array_ref.value[index]['full_message'];
          const code = output.code || ""
          if (code.length > 0) {
            if (output.type == "command") {
              commands.push({
                "type": "command",
                "value": code,
              })
            } else if (output.type == "python") {
              commands.push({
                "type": "python",
                "value": code,
              })
            }
          }
        } else {
          if (e.checked) {
              commands.push(e.value);
              outputs.push(output);
              timestamps.push(timestamp);
          }

          let code_element = document.querySelector('#message_' + index);
          let highlighted_elements = code_element.querySelectorAll('.class_cmd');
          highlighted_elements.forEach((elem) => {
              let highlighted_child_command = elem.innerText;
              // We have to prefix the command with something like @Khais-MacBook-Pro.local for the backend to work
              let parent_command = e.value.trim();
              let parts = parent_command.split(/\s+/)
              let prefixed_child_command = parts[0] + ' ' + highlighted_child_command
              commands.push(prefixed_child_command);
              outputs.push("")
              timestamps.push(timestamp);
          })
        }
        }
    });
    
    if (commands.length > 0) {
        let result = await autoTaskify(props.conv_id, commands, session_title);
        let task_id = result['task']['id'];

        hideWaitPage();
        dkAlert("Task created.  You will now be redirected to the task page.",function(){
            dkNavigateTo(`/tasks/${task_id}`);
        });
    } else {
        hideWaitPage();
        dkAlert("Error: You did not select any checkboxes.");
    }
}

let is_client = ref(false);
if (process.client) {
  is_client.value = true;
}

const code_or_command_count = computed(() => {
    /*
    Iterate over results_array_ref.value and count the messages where subtype == 'ai_code'.
    This is used to control whether we show the checkbox at the top for selecting all, and 
    the button at the "Create Task" button at the bottom.  When the user first create a 
    session from the UI, we should definitely not show these things, but only show the 
    chat box.
    */
    let  count = 0;
    results_array_ref.value.forEach((message) => {
        if ((message?.type == 'aiagent') && (message?.subtype == 'ai_code')) {
            count = count + 1;
        }
    })
    return count;
})

let conv_type = "";
onMounted(async () => {
    let data = await fetchLegacyConvMessages(props.conv_id);
    session_title = data['conv_context']['subject'];
    let conv = data['conv'];
    const keys = Object.keys(data['conv']).sort((a, b) => {
      return parseInt(a) - parseInt(b);
    });
    let results_array = [];

    conv_type = data['conv_context'].conv_type;
    console.log('CONV_TYPE:', conv_type)

    if (data['conv_context'].conv_type == "aiagent") {
        for (let i = 0; i < keys.length; i++) {
            if ((typeof (conv[i]['msg']) != 'string') || (! conv[i]['msg'])) {
                continue;
            }
            let type = conv[i]['full_message']['type'];
            if (type == "command" || type == "python") {                
                let cmd = conv[i]['full_message']['code'];
                let cmd_output = conv[i]['full_message']['stdout'] + conv[i]['full_message']['stderr'];

                let title = conv[i]['msg'];
                let resval = {
                    type: "aiagent",
                    subtype: "ai_code",
                    title: title,
                    cmd : cmd,
                    output: cmd_output,
                    full_message: conv[i]['full_message'],
                    timestamp: conv[i]['tstp']
                }
                results_array.push(resval)

            } else if (type == "usermsg") {
                let fullname = formatUserFirstAndLastName(conv[i].user_info);
                if (fullname != '') {
                    fullname = fullname + ':';
                }

                let user_message = conv[i]['full_message']['msg']['content'];
                let resval = {
                    type: "aiagent",
                    subtype: "user_input",
                    title: "",
                    cmd : fullname,
                    full_message: conv[i]['full_message'],
                    output: user_message,
                    timestamp: conv[i]['tstp']
                }
                results_array.push(resval)
            } else if (type == "assistantmsg") {
                let assisnt_message = JSON.parse(conv[i]['full_message']['msg']['content']);
                let actual_type = assisnt_message["type"];
                let actual_ai_text = assisnt_message["message"];
                if (actual_type == "text") {
                    let resval = {
                        type: "aiagent",
                        subtype: "ai_text",
                        title: "",
                        cmd : "AI:",
                        full_message: conv[i]['full_message'],
                        output: actual_ai_text,
                        timestamp: conv[i]['tstp']
                    }
                    results_array.push(resval)                    
                }
            }
        }
    } else {
        for (let i = 0; i < keys.length; i = i + 2) {
            if ((typeof (conv[i]['msg']) != 'string') || (! conv[i]['msg'])) {
                continue;
            }
            let cmd = conv[i]['msg'];
            let nextconv = conv[i + 1] || {"msg": "", "tstp": conv[i]['tstp']};
            let output = nextconv['msg'];
            results_array.push({
                type: "clisession",
                cmd: cmd,
                output: output,
                timestamp: nextconv['tstp']
            })
        }
    }

    results_array_ref.value = results_array;
});

onUpdated(async () => {
    results_array_ref.value.forEach(function (currentValue, index, arr) {
        let element_id = 'message_' + index;
        if (currentValue["type"] == "aiagent") {
            displayAISessionMessage(document.getElementById(element_id), currentValue);
            
            setTimeout(() => {
                // Here we have to use a timeout because we have to wait for the DOM to finish rendering.
                // If the DOM is not yet finish rendering, the logic to detect if an element has vertical 
                // scrollbar does not work (we want to hide the expand/collaps caret if it is not needed).
                
                // We need to rethink the expand / collapse logic.  Previously, the grey part was typically 
                // just short commands, and the white (or blue) part was the output of that command.  The 
                // command is short (in term of height, and was acting as the title for the message), and the output can be long.  
                // Now, we put scripts in to the grey part, and the output of the script can be short.
                // The expand / collapse was previously applied to only the output DIV.  Now we need to 
                // apply the expand / collapse logic to DIV that previously hold the command.

                // Look for element where the command / title (grey bar) is empty, and hide them.
                document.querySelectorAll('.class_cli_command').forEach((element) => {
                    if (element.querySelector('code')?.innerText.trim() == '') {
                        element.style.display = 'none';
                        element.parentNode.nextElementSibling.querySelector('.class_execution_results_box').classList.add('force_border_top')
                        // element.parentNode.nextSibling
                    }
                });
                // Look for the main output areas, and if the height is less than 200px and not overflowing, hide the 
                // expand / collapse caret.
                document.querySelectorAll('.class_execution_results_box').forEach((element) => {
                    if (element.scrollHeight <= element.clientHeight) {
                        element.nextSibling.style.display = 'none';
                    }
                })
            }, 100)

        } else {
          let output = currentValue['output'];
          displayTaskExecResult(document.getElementById(element_id), output, currentValue);
        }
    })
});

const send_message = async (event) => {
    if (generated_access_token == "") {
        generated_access_token = await generate_access_token_for_current_user()
    }
    if (!execute_proxy_alias) {
        dkAlert("Please select a proxy.");
        return;
    }

    let result = await callLLM(props.conv_id, execute_proxy_alias, input_message_ref.value, generated_access_token)
    if (auto_execute) {
        if (result?.msg?.type == 'command') {
            let command = result?.msg?.message;
            result = await executeCommand(props.conv_id, execute_proxy_alias, command, generated_access_token);
        }
    }
    input_message_ref.value = "";
}

</script>


<style scoped>
.create_task_btn_container {
    text-align: center;
}

#session_detail_select_or_unselect_all {
    margin-right: 5px;
    float: right;
}
.instruction {
    padding-left: 5px;
}

.message_input_field {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
}
.create_task_btn_container button {
    margin: 0 5px;
}
.auto_exec_label {
    margin-left: 10px;
    position: relative;
}
.auto_exec_label input {
    position: relative;
    top: 2px;
    left: -3px;
}
</style>
