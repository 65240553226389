<template>
    <div class="space_between_messages" :class="assign_other_css_classes(message)">
        <div class="position-relative our_codemirror_readonly">
            <div class="class_cli_command">
                <codemirror 
                    v-if="(message?.type == 'aiagent') && (message?.subtype == 'ai_code')"
                    ref="myEditor_ref"
                    v-model="message['cmd']"
                    :style="{}"
                    :autofocus="true"
                    disabled="disabled"
                    :indent-with-tab="true"
                    :tab-size="2"
                    :readOnly="true"
                    :editable="false"
                    placeholder=""
                    :options="codemirrorOptions"
                    :extensions="command_extensions"
                    @ready="handleReady"
                />
                <pre v-if="(message?.type != 'aiagent') || (message?.subtype != 'ai_code')"><code >{{ message['cmd'] }}</code></pre>

                <span class="icon_container">
                    <span class="fa-highlighter-line text-right class_cmd_highlight_button" title="Highlight commands" :class="highlight_commands_enabled_ref.valueOf() ? 'fa-solid' : 'fa-light'" @click="enable_highlight_commands" data-bs-toggle="tooltip" data-bs-placement="left"/>
                    <span class="fa fa-xmark text-right class_cmd_highlight_cancel_button" title="Undo highlight" @click="undo_highlight_commands" data-bs-toggle="tooltip" data-bs-placement="left"/>
                    <input class="form-check-input auto_dagify_checkboxes" type="checkbox" :value="message['cmd']" :checked="true" :id="'dagify_' + index"/>
                </span>
            </div>
        </div>
        <TaskDisplayExecResult :element_id="'message_' + index"/>
    </div>
</template>

<script setup>
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { oneDark } from "@codemirror/theme-one-dark";
import { basicSetup, EditorView } from "codemirror";

const props = defineProps({
    message: {
        type: Object,
        required: true 
    },
    index: {
        type: Number,
        required: true 
    },

})

let codemirrorOptions = {
  readOnly: true,
  editable: false,
};

const command_extensions = [python(), oneDark]; // When displaying the commands (not editting), we do want the color as though it was python.
const code_extensions = [oneDark];

// Codemirror EditorView instance ref
const view = shallowRef();
const handleReady = (payload) => {
  view.value = payload.view;
  const state = view.value.state;
  //payload.view.contentDOM.setAttribute('contenteditable',"false");
  // EditorView.editable.of(false)
  //payload.state.readOnly.of(true)
  //state.readOnly.of(true);
};


const assign_other_css_classes = (msg) => {
    let result = {};
    if (msg?.type == 'aiagent') {
        result['aisession'] = true;
        if (msg?.subtype != '') {
            result[msg.subtype] = true;
        }
    } else if (msg?.type == 'clisession') {
        result['clisession'] = true;
    }
    return result;
}

let is_client = ref(false);
if (process.client) {
  is_client.value = true;
}

let highlight_commands_enabled_ref = ref(false);

const enable_highlight_commands = (event) => {
    highlight_commands_enabled_ref.value = true;
    // class_cli_command
    let element = findAncestorWithClass(event.target, "class_cli_command").parentElement.parentElement;
    let code_element = element.querySelector('.left_side_execution_result');
    if (code_element.querySelectorAll('div').length == 0) {
        let text = code_element.innerText;
        var msg_lines = text.split(/\r?\n/);
        code_element.innerHTML = ""
        for (var l=0; l < msg_lines.length; l++){
            var msg_line_div = document.createElement("div");
            msg_line_div.className = "class_exec_line";
            if (msg_lines[l] == '') {
                msg_lines[l] = "\n"
            }
            msg_line_div.innerText = msg_lines[l];
            msg_line_div.onmouseup = (event) => {
                if (highlight_commands_enabled_ref.value){
                    var cmd = getSelectionText();
                    if (cmd.trim() != "") {
                        var parts = event.target.innerText.split(cmd);
                        var highlighted_line = parts.join("<span class=\"class_cmd\">" + cmd + "</span>");
                        event.target.innerHTML = highlighted_line;
                    }
                }
            };
            code_element.appendChild(msg_line_div);
        }

    }
}

const undo_highlight_commands = (event) => {
    highlight_commands_enabled_ref.value = false;
    // class_cli_command
    let element = findAncestorWithClass(event.target, "class_cli_command").parentElement.parentElement;
    let code_element = element.querySelector('.left_side_execution_result');
    if (code_element) {
        var cmds = code_element.querySelectorAll(".class_cmd");
        for (var c=0; c < cmds.length; c++){
            cmds[c].classList.remove("class_cmd");
        }
    }
}

function getSelectionText() {
  var text = "";
  if (window.getSelection) {
      text = window.getSelection().toString();
  } else if (document.selection && document.selection.type != "Control") {
      text = document.selection.createRange().text;
  }
  return text;
}

</script>

<style scoped>
.icon_container {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
}

.icon_container > * {
    margin-left: 10px;
    cursor: pointer;
}
.create_task_btn_container {
    text-align: center;
}
.class_cli_command {
    background-color: #ddd;
    padding: 5px;
    font-family: monospace;
    font-size: 14px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.space_between_messages {
    margin-bottom: 20px;
}
</style>