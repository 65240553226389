export const autoTaskify = async (conv_id, commands, session_title) => {
    const result = await logFetchAJAX(getUrl('/api/tasks/autoTaskify'), {
        method: 'POST',
        body: { 
            commands: commands,
            session_title: session_title,
            conv_id: conv_id,
        }
    });
    return result;
}
