export const displayTaskExecResult = (code_element, html, exec_result) => {
    code_element.innerHTML = html;
    let failed = false;
    if (html.search(/\S+/) > -1) {
      code_element.parentElement.classList.add('left_side_execution_result_with_padding_and_border');
      let hasVerticalScrollbar = code_element.parentElement.scrollHeight > code_element.parentElement.clientHeight;
      if (hasVerticalScrollbar) {
        code_element.parentElement.nextSibling.style.display = 'block';
      } else {
        code_element.parentElement.nextSibling.style.display = 'none';
      }
    } else {
      code_element.parentElement.classList.remove('left_side_execution_result_with_padding_and_border');
    }

    /*
    This function is currently used only for creating tasks from a CLI sessions.
    We will assume that there is no failure.  Later on, if we want to use this function in more places,
    we will need to examine the exec_result parameter, and set the failed variable accordingly.
    */
    if (failed) {
      code_element.parentElement.classList.add('left_side_execution_result_failed');
    } else {
      code_element.parentElement.classList.remove('left_side_execution_result_failed')
    }
}
